export function getModelUnitText(type){
    if(type == 1){
        return "次数"
    }else if(type == 2){
        return "token数"
    }else if(type == 3){
        return "字数"
    }else if(type == 4){
        return "秒数"
    }else if(type == 5){
        return "算力"
    }
}
export function getModelBaseNumber(type){
    if(type == 1){
        return ''
    }else if(type == 2){
        return "1000个"
    }else if(type == 3){
        return "100字"
    }else if(type == 4){
        return "60秒"
    }else if(type == 5){
        return 1
    }
}
export function getModelBaseNumberValue(type){
    if(type == 1){
        return ''
    }else if(type == 2){
        return 1000
    }else if(type == 3){
        return 100
    }else if(type == 4){
        return 60
    }
}
export function getModelUnitText_s(type){
    if(type == 1){
        return "次"
    }else if(type == 2){
        return " token"
    }else if(type == 3){
        return "字"
    }else if(type == 4){
        return "秒"
    }else if(type == 5){
        return "算力"
    }
}
export function getModelBaseNumber_s(type){
    if(type == 1){
        return 1
    }else if(type == 2){
        return 1000
    }else if(type == 3){
        return 100
    }else if(type == 4){
        return 60
    }else if(type == 5){
        return 1
    }
}