<template>
   <div class="modelSetItemWrap">
        <div class="name">{{ modelName }}:</div>
        <!-- <el-checkbox v-model="checked"></el-checkbox> -->

        <el-switch v-model="checked" active-color="#13ce66" inactive-color="#ff4949" @change="checkedChange"></el-switch>

        <div class="t1">{{ getModelUnit }}：</div>
        <el-input v-model="number" @change="numberChange" class="input" />

        <!-- <div class="t2" v-if="!hidePrice">价格(元/{{ getBaseNum }})：</div>
        <el-input v-if="!hidePrice" v-model="price" class="input" @change="priceChange"/> -->

        <!-- <div class="t2">成本价：</div>
        <div>{{ costPrice }}</div> -->
   </div>
</template>

<script>
import { getModelUnitText, getModelBaseNumber } from '@/utils/constant'
import { mapState } from 'vuex'

export default {
components: {  },
data() {
   return {
        modelName:'',
        checked:false,
        number:0,
        modelValue:1,
        price:0,
        unitType:0,
        costPrice:0,
   }
},
props:["itemData", "hidePrice"],

computed:{
    ...mapState([ ]),
    getModelUnit(){
        return getModelUnitText(this.unitType)
    },
    getBaseNum(){
        return getModelBaseNumber(this.unitType)
    }
},
async mounted() {

},
methods: {
    numberChange(e){
        let obj = {
            checked: this.checked,
            price: this.costPrice,
            modelValue: this.modelValue,
            number: e,
            unitType: this.unitType,
        }
        this.$emit("dataChange",obj)
    },
    checkedChange(e){
        let obj = {
            checked: e,
            price: this.costPrice,
            modelValue: this.modelValue,
            number: this.number,
            unitType: this.unitType,
        }
        this.$emit("dataChange",obj)
    },
    priceChange(e){
        if(e<this.costPrice){
            this.price = this.costPrice
        }else{
            this.price = e
        }
        let obj = {
            checked: this.checked,
            price: this.price,
            modelValue: this.modelValue,
        }
        this.$emit("dataChange",obj)
    },
    init({checked,number,modelValue,price,modelName,unitType,costPrice}){
        this.checked = checked
        this.number = number
        this.modelValue = modelValue
        this.price = costPrice
        this.modelName = modelName
        this.unitType = unitType
        if(costPrice){
            this.costPrice = JSON.parse(JSON.stringify(costPrice))
        }

    },
    check(){
        if(this.checked){
            if(this.number && this.costPrice){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    },

    getInfo(){
        return {
            checked:this.checked,
            number:this.number,
            modelValue:this.modelValue,
            price:this.costPrice,
            modelName:this.modelName
        }
    }
}
}
</script>

<style lang='scss' scoped>

.modelSetItemWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.name{
    width: 160px;
    text-align: right;
    padding-right: 10px;
}
.input{
    width: 110px;
}
.t1{
    width: 90px;
    padding: 0 10px;
    text-align: right;
}
.t2{
    width: 150px;
    padding: 0 10px;
    text-align: right;
}
@media screen and (max-width: 900px){

}

</style>